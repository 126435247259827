/**
 * Theme global.js
 */

function init() {
    'use strict';
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {
        setBodyPaddingBottom();
        $('.desktop-main-menu a[href*="#"]').on('click', function (e) {
            e.preventDefault();
        });
        $('.desktop-main-menu .menu-item-has-children').on('click', function (e) {
            $(this).toggleClass('active');
            $(this).siblings().removeClass('active');
        });
        //Close submenus when clicking outside
        $(document).on('click', function (e) {
            if (!$(e.target).closest('.desktop-main-menu .menu-item-has-children').length) {
                $('.desktop-main-menu .menu-item-has-children').removeClass('active');
            }
        });
        $('.hamburger, .close-button').on('click', function (e) {
            $('.mobile-main-menu').toggleClass('closed open');
        });

        let swiper = new Swiper(".swiper.hero", {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 6000,
            },
        });

        $('#journey-planner-url, #contactless-portal-url').on('change', function () {
            window.location.href = $(this).val();
        });

        $('.mobile-main-menu .menu > li.menu-item-has-children > a').on('click', (function (e) {
            e.preventDefault();
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
            $(this).parent().siblings().removeClass('active');
            $(this).parent().toggleClass('active');
            $(this).parent().siblings().children('.sub-menu').slideUp(250);
            $(this).siblings('.sub-menu').slideToggle(250);
        }));
    });

    $(window).resize(function () {
        setBodyPaddingBottom();
    });

    function setBodyPaddingBottom() {
        let footerHeight = $('body:not(.wp-admin) footer').outerHeight();
        $('body:not(.wp-admin)').css('padding-bottom', footerHeight);
    }
})(jQuery);

